import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import ls from 'local-storage';

import axios from '../../utils/axios';
import { history } from '../../routers/AppRouter';

const initialState = {
  profile: {},
  householdMembers: [],
  programs: [],
  utilities: [],
  landlords: [],
  isEsignReady: false,
  loading: false,
  status: {
    status: '',
    message: '',
    payments: [],
    submitted: '',
  },
  income: [],
  messages: [],
  counties: [],
  cities: [],
};


const slice = createSlice({
  name: 'tenantApplication',
  initialState,
  reducers: {
    tenantApplicationBegin: (tenantApplication) => {
      tenantApplication.loading = true;
    },
    tenantApplicationEnd: (tenantApplication) => {
      tenantApplication.loading = false;
    },

    // TENANT PROFILE
    getTenantInfo: (tenantApplication, action) => {
      tenantApplication.loading = false;
      tenantApplication.profile = action.payload;
    },
    updateTenantInfo: (tenantApplication) => {
      tenantApplication.loading = false;
    },
    signTenant: (tenantApplication) => {
      tenantApplication.loading = false;
    },
    getLLSearch: (tenantApplication, action) => {
      tenantApplication.loading = false;
      tenantApplication.landlords = action.payload;
    },
    resetLLSearch: (tenantApplication, action) => {
      tenantApplication.loading = false;
      tenantApplication.landlords = [];
    },
    inviteLandlord: (tenantApplication, action) => {
      tenantApplication.loading = false;
      tenantApplication.landlords = action.payload;
    },
    notifyLandlord: (tenantApplication) => {
      tenantApplication.loading = false;
    },
    // HOUSEHOLD MEMBERS
    getHouseholdMembers: (tenantApplication, action) => {
      tenantApplication.householdMembers = action.payload;
      tenantApplication.loading = false;
    },
    getHouseholdMember: (tenantApplication) => {
      tenantApplication.loading = false;
    },
    addHouseholdMember: (tenantApplication) => {
      tenantApplication.loading = false;
    },
    updateHouseholdMember: (tenantApplication) => {
      tenantApplication.loading = false;
    },
    deleteHouseholdMember: (tenantApplication) => {
      tenantApplication.loading = false;
    },

    // PROGRAMS
    getPrograms: (tenantApplication, action) => {
      tenantApplication.programs = action.payload;
      tenantApplication.loading = false;
    },
    getProgram: (tenantApplication) => {
      tenantApplication.loading = false;
    },
    addProgram: (tenantApplication) => {
      tenantApplication.loading = false;
    },
    updateProgram: (tenantApplication) => {
      tenantApplication.loading = false;
    },
    deleteProgram: (tenantApplication) => {
      tenantApplication.loading = false;
    },

    // UTILITIES
    getUtilities: (tenantApplication, action) => {
      tenantApplication.utilities = action.payload;
      tenantApplication.loading = false;
    },
    getUtility: (tenantApplication) => {
      tenantApplication.loading = false;
    },
    addUtility: (tenantApplication) => {
      tenantApplication.loading = false;
    },
    updateUtility: (tenantApplication) => {
      tenantApplication.loading = false;
    },
    deleteUtility: (tenantApplication) => {
      tenantApplication.loading = false;
    },

    // INCOMES
    getIncome: (tenantApplication, action) => {
      tenantApplication.income = action.payload;
      tenantApplication.loading = false;
    },
    addIncome: (tenantApplication) => {
      tenantApplication.loading = false;
    },
    updateIncome: (tenantApplication) => {
      tenantApplication.loading = false;
    },
    deleteIncome: (tenantApplication) => {
      tenantApplication.loading = false;
    },

    // SET E-SIGN READY
    setEsignReady: (tenantApplication, action) => {
      tenantApplication.isEsignReady = action.payload;
      tenantApplication.loading = false;
    },

    // GET APPLICATION STATUS
    getStatus: (tenantApplication, action) => {
      tenantApplication.status = action.payload;
      tenantApplication.loading = false;
    },

    // GET COUNTY
    getCounties: (tenantApplication, action) => {
      tenantApplication.counties = action.payload;
      tenantApplication.loading = false;
    },
    getCities: (tenantApplication, action) => {
      tenantApplication.cities = action.payload;
      tenantApplication.loading = false;
    },

    // MESSAGE
    getMessages: (tenantApplication, action) => {
      tenantApplication.messages = action.payload;
      tenantApplication.loading = false;
    },
    sendMessage: (tenantApplication) => {
      tenantApplication.loading = false;
    },
    updateMessage: (tenantApplication) => {
      tenantApplication.loading = false;
    },
  },
});

/// //////////////////////////////
/// ///// TENANT PROFILE ////////
/// ////////////////////////////

// GET_TENANT_APPLICANT_INFO
export const startGetTenantProfile = (cb) => async (dispatch, getState) => {
  dispatch(tenantApplicationBegin());
  try {
    const response = await axios.get(`${baseURL}/user/tenantProfile`);
    await dispatch(getTenantInfo(response.data));
    if (cb) cb();
  } catch (error) {
    console.log(error.response);
    dispatch(tenantApplicationEnd());
  }
};

// UPDATE_TENANT_APPLICANT_INFO
export const startUpdateTenantProfile = (payload, setSubmitting, noRedirectToDashboard, refetch) => async (dispatch, getState) => {
  dispatch(tenantApplicationBegin());
  try {
    const response = await axios.put(`${baseURL}/user/tenantProfile`, payload);
    await dispatch(updateTenantInfo(response.data));
    if (!noRedirectToDashboard) {
      toast.success('Profile updated!');
      history.push('/application/tenant');
    }
    if (refetch) {
      await dispatch(startGetTenantProfile());
    }
  } catch (error) {
    if (error.response.status === 409) {
      toast.error(error.response.data.message);
    }
    setSubmitting();
    console.log(error.response);
    dispatch(tenantApplicationEnd());
  }
};

// NOTIFY_LANDLORD
export const startNotifyLandlord = (payload) => async (dispatch, getState) => {
  dispatch(tenantApplicationBegin());
  try {
    const response = await axios.post(`${baseURL}/notify-connection`, payload);
    dispatch(notifyLandlord());
    dispatch(tenantApplicationEnd());
  } catch (error) {
    console.log(error.response);
    dispatch(tenantApplicationEnd());
  }
};

// SIGN_TENANT
export const startAddTenantSignature = (payload, setSubmitting) => async (dispatch, getState) => {
  dispatch(tenantApplicationBegin());
  try {
    const response = await axios.post(`${baseURL}/signature/tenant`, payload);
    dispatch(signTenant());
    toast.success('Signed!');
    history.push('/application/tenant');
  } catch (error) {
    setSubmitting();
    console.log(error.response);
    dispatch(tenantApplicationEnd());
  }
};

// GET_LL_SEARCH
export const startGetLandLords = (email) => async (dispatch, getState) => {
  dispatch(tenantApplicationBegin());
  try {
    // ls('email', email);
    const encodedEmail = encodeURIComponent(email);
    if (encodedEmail) {
      const response = await axios.get(`${baseURL}/landlords/?q=${encodedEmail}`);
      await dispatch(getLLSearch(response.data));
    }
  } catch (error) {
    console.log(error.response);
    dispatch(tenantApplicationEnd());
  }
};

// GET_LL_SEARCH
export const startGetLandLordsByID = (landlordProfileID) => async (dispatch, getState) => {
  dispatch(tenantApplicationBegin());
  try {
    const response = await axios.get(`${baseURL}/landlords/search/${landlordProfileID}`);
    await dispatch(getLLSearch(response.data));
  } catch (error) {
    console.log(error.response);
    dispatch(tenantApplicationEnd());
  }
};

// INVITE_LANDLORD
export const startInviteLandlord = (payload) => async (dispatch, getState) => {
  dispatch(tenantApplicationBegin());
  try {
    const response = await axios.post(`${baseURL}/invite`, payload);
    dispatch(inviteLandlord(response.data));
    toast.success('Invite Sent!');
    history.push('/application/tenant');
    ls.remove('email');
  } catch (error) {
    console.log(error.response);
    dispatch(tenantApplicationEnd());
  }
};

/// //////////////////////////////
/// ////// HOUSE MATES //////////
/// ////////////////////////////

// GET_HOUSEHOLD_MEMBERS
export const startGetHouseholdMembers = () => async (dispatch, getState) => {
  dispatch(tenantApplicationBegin());
  try {
    const response = await axios.get(`${baseURL}/houseMates`);
    dispatch(getHouseholdMembers(response.data));
  } catch (error) {
    console.log(error.response);
    dispatch(tenantApplicationEnd());
  }
};

// GET_HOUSEHOLD_MEMBER
// export const startGetHouseholdMember = (householdMemberID) => {
//   return async(dispatch, getState) => {
//     dispatch(tenantApplicationBegin())
//     try {
//       const response = await axios.get(`${baseURL}/houseMates/${householdMemberID}`)
//       dispatch(getHouseholdMember(response.data))
//     } catch (error) {
//       console.log(error.response)
//       dispatch(tenantApplicationEnd())
//     }
//   }
// }

// ADD_HOUSEHOLD_MEMBER
export const startAddHouseholdMembers = (payload, setSubmitting, toggle) => async (dispatch, getState) => {
  dispatch(tenantApplicationBegin());
  try {
    const response = await axios.post(`${baseURL}/houseMates`, payload);
    dispatch(addHouseholdMember());
    dispatch(startGetHouseholdMembers());
    toggle();
    toast.success('Created!');
  } catch (error) {
    console.log(error.response);
    setSubmitting();
    dispatch(tenantApplicationEnd());
  }
};

// UPDATE_HOUSEHOLD_MEMBER
export const startUpdateHouseholdMember = (householdMemberID, payload, setSubmitting, toggle) => async (dispatch, getState) => {
  dispatch(tenantApplicationBegin());
  try {
    const response = await axios.put(`${baseURL}/houseMates/${householdMemberID}`, payload);
    dispatch(updateHouseholdMember());
    dispatch(startGetHouseholdMembers());
    toggle();
    toast.success('Updated!');
  } catch (error) {
    setSubmitting();
    console.log(error.response);
    dispatch(tenantApplicationEnd());
  }
};

// DELETE_HOUSEHOLD_MEMBER
export const startDeleteHouseholdMember = (householdMemberID, setSubmitting, toggle, isModal) => async (dispatch, getState) => {
  dispatch(tenantApplicationBegin());
  try {
    const response = await axios.delete(`${baseURL}/houseMates/${householdMemberID}`);
    await dispatch(deleteHouseholdMember());
    if (isModal) {
      await dispatch(startGetHouseholdMembers());
      toggle();
      toast.warning('Deleted!');
    }
  } catch (error) {
    setSubmitting();
    console.log(error.response);
    dispatch(tenantApplicationEnd());
  }
};

/// /////////////////////////////////////
/// ////// PROGRAM ASSISTANCE //////////
/// ///////////////////////////////////

// GET_PROGRAMS
export const startGetPrograms = () => async (dispatch, getState) => {
  dispatch(tenantApplicationBegin());
  try {
    const response = await axios.get(`${baseURL}/programAssistance`);
    dispatch(getPrograms(response.data));
  } catch (error) {
    console.log(error.response);
    dispatch(tenantApplicationEnd());
  }
};

// GET_PROGRAM
// export const startGetProgram = (programID) => {
//   return async(dispatch, getState) => {
//     dispatch(tenantApplicationBegin())
//     try {
//       const response = await axios.get(`${baseURL}/programAssistance/${programID}`)
//       dispatch(getProgram(response.data))
//     } catch (error) {
//       console.log(error.response)
//       dispatch(tenantApplicationEnd())
//     }
//   }
// }

// ADD_PROGRAM
export const startAddProgram = (payload, setSubmitting, toggle) => async (dispatch, getState) => {
  dispatch(tenantApplicationBegin());
  try {
    const response = await axios.post(`${baseURL}/programAssistance`, payload);
    dispatch(addProgram());
    dispatch(startGetPrograms());
    toggle();
    toast.success('Created!');
  } catch (error) {
    console.log(error.response);
    setSubmitting();
    dispatch(tenantApplicationEnd());
  }
};

// UPDATE_PROGRAM
export const startUpdateProgram = (programID, payload, setSubmitting, toggle) => async (dispatch, getState) => {
  dispatch(tenantApplicationBegin());
  try {
    const response = await axios.put(`${baseURL}/programAssistance/${programID}`, payload);
    dispatch(updateProgram());
    dispatch(startGetPrograms());
    toggle();
    toast.success('Updated!');
  } catch (error) {
    setSubmitting();
    console.log(error.response);
    dispatch(tenantApplicationEnd());
  }
};

// DELETE_PROGRAM
export const startDeleteProgram = (programID, toggle) => async (dispatch, getState) => {
  dispatch(tenantApplicationBegin());
  try {
    const response = await axios.delete(`${baseURL}/programAssistance/${programID}`);
    dispatch(deleteProgram());
    dispatch(startGetPrograms());
    toggle();
    toast.warning('Deleted!');
  } catch (error) {
    console.log(error.response);
    dispatch(tenantApplicationEnd());
  }
};

/// ////////////////////////////
/// ////// UTILITIES //////////
/// //////////////////////////

// GET_UTILITIES
export const startGetUtilities = () => async (dispatch, getState) => {
  dispatch(tenantApplicationBegin());
  try {
    const response = await axios.get(`${baseURL}/utility`);
    dispatch(getUtilities(response.data));
  } catch (error) {
    console.log(error.response);
    dispatch(tenantApplicationEnd());
  }
};

// GET_UTILITY
// export const startGetUtility = (utilityID) => {
//   return async(dispatch, getState) => {
//     dispatch(tenantApplicationBegin())
//     try {
//       const response = await axios.get(`${baseURL}/utility/${utilityID}`)
//       dispatch(getUtility(response.data))
//     } catch (error) {
//       console.log(error.response)
//       dispatch(tenantApplicationEnd())
//     }
//   }
// }

// ADD_UTILITY
export const startAddUtility = (payload, setSubmitting, toggle) => async (dispatch, getState) => {
  dispatch(tenantApplicationBegin());
  try {
    const response = await axios.post(`${baseURL}/utility`, payload);
    dispatch(addUtility());
    dispatch(startGetUtilities());
    toggle();
    toast.success('Created!');
  } catch (error) {
    console.log(error.response);
    setSubmitting();
    dispatch(tenantApplicationEnd());
  }
};

// UPDATE_UTILITY
export const startUpdateUtility = (utilityID, payload, setSubmitting, toggle) => async (dispatch, getState) => {
  dispatch(tenantApplicationBegin());
  try {
    const response = await axios.put(`${baseURL}/utility/${utilityID}`, payload);
    dispatch(updateUtility());
    dispatch(startGetUtilities());
    toggle();
    toast.success('Updated!');
  } catch (error) {
    console.log(error.response);
    setSubmitting();
    dispatch(tenantApplicationEnd());
  }
};

// DELETE_UTILITY
export const startDeleteUtility = (utilityID, toggle) => async (dispatch, getState) => {
  dispatch(tenantApplicationBegin());
  try {
    const response = await axios.delete(`${baseURL}/utility/${utilityID}`);
    dispatch(deleteUtility());
    dispatch(startGetUtilities());
    toggle();
    toast.warning('Deleted!');
  } catch (error) {
    console.log(error.response);
    dispatch(tenantApplicationEnd());
  }
};

/// ////////////////////////////////////
/// ////// APPLICATION STATUS //////////
/// ////////////////////////////////////

// GET_APP_STATUS
export const startGetStatus = () => async (dispatch, getState) => {
  dispatch(tenantApplicationBegin());
  try {
    const response = await axios.get(`${baseURL}/tenant/status`);
    dispatch(getStatus(response.data));
  } catch (error) {
    console.log(error.response);
    dispatch(tenantApplicationEnd());
  }
};

/// ////////////////////////////
/// ////// INCOME //////////
/// //////////////////////////

// GET_INCOME
export const startGetIncome = () => async (dispatch, getState) => {
  dispatch(tenantApplicationBegin());
  try {
    const response = await axios.get(`${baseURL}/income`);
    dispatch(getIncome(response.data));
  } catch (error) {
    console.log(error.response);
    dispatch(tenantApplicationEnd());
  }
};

// ADD_INCOME
export const startAddIncome = (payload, setSubmitting, toggle, calculateIncome) => async (dispatch, getState) => {
  dispatch(tenantApplicationBegin());
  try {
    const response = await axios.post(`${baseURL}/income`, payload);
    await dispatch(startGetIncome());
    const { income } = getState().tenantApplication;
    const totalIncome = calculateIncome(income);
    await dispatch(startUpdateTenantProfile({
      totalAnnualIncome: totalIncome,
      totalMonthlyIncome: totalIncome / 12,
    }, setSubmitting, true));
    await dispatch(startGetTenantProfile());
    dispatch(addIncome());
    toggle();
    toast.success('Created!');
  } catch (error) {
    console.log(error.response);
    setSubmitting();
    dispatch(tenantApplicationEnd());
  }
};

// UPDATE_INCOME
export const startUpdateIncome = (incomeID, payload, setSubmitting, toggle, calculateIncome) => async (dispatch, getState) => {
  dispatch(tenantApplicationBegin());
  try {
    const response = await axios.put(`${baseURL}/income/${incomeID}`, payload);
    await dispatch(startGetIncome());
    const { income } = getState().tenantApplication;
    const totalIncome = calculateIncome(income);
    await dispatch(startUpdateTenantProfile({
      totalAnnualIncome: totalIncome,
      totalMonthlyIncome: totalIncome / 12,
    }, setSubmitting, true));
    await dispatch(startGetTenantProfile());
    dispatch(updateIncome());
    toggle();
    toast.success('Updated!');
  } catch (error) {
    console.log(error.response);
    setSubmitting();
    dispatch(tenantApplicationEnd());
  }
};

// DELETE_INCOME
export const startDeleteIncome = (incomeID, toggle, calculateIncome, isModal) => async (dispatch, getState) => {
  dispatch(tenantApplicationBegin());
  try {
    const response = await axios.delete(`${baseURL}/income/${incomeID}`);
    await dispatch(startGetIncome());
    dispatch(deleteIncome());
    if (isModal) {
      const { income } = getState().tenantApplication;
      const totalIncome = calculateIncome(income);
      const payload = {
        totalAnnualIncome: totalIncome,
        totalMonthlyIncome: totalIncome / 12,
      };
      await dispatch(startUpdateTenantProfile(payload, () => { }, true));
      await dispatch(startGetTenantProfile());
      toggle();
      toast.warning('Deleted!');
    }
  } catch (error) {
    console.log(error.response);
    dispatch(tenantApplicationEnd());
  }
};

// /////////////////////////////////////
// //////////// COUNTIES ///////////////
// /////////////////////////////////////

// GET_COUNTIES
export const startGetCounties = () => async (dispatch, getState) => {
  dispatch(tenantApplicationBegin());
  try {
    const response = await axios.get(`${baseURL}/counties`);
    const counties = response.data.map((d) => d.name.trim());
    await dispatch(getCounties(counties));
  } catch (error) {
    console.log(error.response);
    dispatch(tenantApplicationEnd());
  }
};


// GET_CITIES
export const startGetCities = () => async (dispatch, getState) => {
  dispatch(tenantApplicationBegin());
  try {
    const response = await axios.get(`${baseURL}/cities`);
    await dispatch(getCities(response.data));
  } catch (error) {
    console.log(error.response);
    dispatch(tenantApplicationEnd());
  }
};

/// ////////////////////////////
/// ////// MESSAGES //////////
/// //////////////////////////

// GET_MESSAGES
export const startGetMessages = () => async (dispatch, getState) => {
  dispatch(tenantApplicationBegin());
  try {
    const response = await axios.get(`${baseURL}/messages`);
    dispatch(getMessages(response.data.conversation));
  } catch (error) {
    console.log(error.response);
    dispatch(tenantApplicationEnd());
  }
};

// SEND_MESSAGE
export const startSendMessage = (payload, setSubmitting, resetForm) => async (dispatch, getState) => {
  dispatch(tenantApplicationBegin());
  try {
    const response = await axios.post(`${baseURL}/messages`, payload);
    dispatch(sendMessage());
    dispatch(startGetMessages());
    toast.success('Sent!');
    setSubmitting();
    resetForm({
      values: {
        text: '',
      },
    });
    dispatch(startUpdateMessage());
  } catch (error) {
    console.log(error.response);
    setSubmitting();
    dispatch(tenantApplicationEnd());
  }
};

// UPDATE_MESSAGE
export const startUpdateMessage = () => async (dispatch, getState) => {
  dispatch(tenantApplicationBegin());
  try {
    await dispatch(startGetMessages());
    const { messages } = getState().tenantApplication;
    const messageIDs = messages.filter((f) => f.type === 'admin').map((d) => d.messageID);
    const payload = {
      messageIDs,
    };
    const response = await axios.put(`${baseURL}/messages/read`, payload);
    dispatch(updateMessage());
    dispatch(startGetMessages());
  } catch (error) {
    console.log(error.response);
    dispatch(tenantApplicationEnd());
  }
};

export const {
  tenantApplicationBegin,
  tenantApplicationEnd,
  getTenantInfo,
  updateTenantInfo,
  signTenant,
  getLLSearch,
  resetLLSearch,
  inviteLandlord,
  notifyLandlord,
  getHouseholdMembers,
  getHouseholdMember,
  addHouseholdMember,
  updateHouseholdMember,
  deleteHouseholdMember,
  getPrograms,
  getProgram,
  addProgram,
  updateProgram,
  deleteProgram,
  getUtilities,
  getUtility,
  addUtility,
  updateUtility,
  deleteUtility,
  getIncome,
  addIncome,
  updateIncome,
  deleteIncome,
  setEsignReady,
  getStatus,
  getCounties,
  getCities,
  getMessages,
  sendMessage,
  updateMessage,
} = slice.actions;

export default slice.reducer;
